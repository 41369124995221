<template>
  <el-row :gutter="20">
    <el-col :span="3">
      <el-button @click="handleAddClick" icon="el-icon-plus" type="primary"
        >新增供应商</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Nav",
  methods: {
    handleAddClick() {
      this.$emit("add");
    },
  },
};
</script>
<style lang="less" scoped>
</style>