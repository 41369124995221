<template>
  <div class="sup-container">
    <sup-nav @add="handleAddItem"></sup-nav>
    <sup-list
      :list="supplierList"
      @edit="handleEditItem"
      @succ="handleSucc"
    ></sup-list>
    <edit-sup
      :show="show"
      :type="type"
      :item="item"
      @succ="handleSucc"
    ></edit-sup>
  </div>
</template>
<script>
import SupNav from "./components/Nav";
import SupList from "./components/List";
import EditSup from "./components/EditSup";
export default {
  name: "Supplier",
  components: {
    SupNav,
    SupList,
    EditSup,
  },
  data() {
    return {
      type: "add",
      show: false,
      item: {},
      supplierList: [],
    };
  },
  created() {
    this.getAllSuppliers();
  },
  methods: {
    //获取供应商列表
    async getAllSuppliers() {
      this.supplierList = await this.$store.dispatch("getAllSuppliers", true);
      
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //提交成功
    handleSucc() {
      this.getAllSuppliers();
    },
  },
};
</script>
<style lang="less" scoped>
</style>