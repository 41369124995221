<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号" align="center">
    </el-table-column>
    <el-table-column prop="name" label="供应商" align="center">
    </el-table-column>
    <el-table-column prop="code" label="简码" align="center"> </el-table-column>
    <el-table-column prop="color_code" label="颜色" align="center">
      <div class="item" slot-scope="scope">
        <div
          class="color-btn"
          :style="{ backgroundColor: scope.row.color_code }"
        ></div>
      </div>
    </el-table-column>
    <el-table-column prop="desc" label="备注" align="center"> </el-table-column>
    <el-table-column prop="created_at" label="创建日期" align="center">
    </el-table-column>
    <el-table-column prop="updated_at" label="修改日期" align="center">
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
import KyImage from "@/components/KyImage";
export default {
  name: "List",
  components: { KyImage },
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  methods: {
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delSupplier", {
        sup_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-btn {
  width: 50px;
  height: 30px;
}
</style>