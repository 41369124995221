<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      :rules="rules"
      :model="fromData"
      ref="fromData"
    >
      <el-form-item label="名称:" prop="name">
        <el-input v-model.trim="fromData.name"></el-input>
      </el-form-item>
      <el-form-item label="简码:" prop="code">
        <el-input v-model.trim="fromData.code"></el-input>
      </el-form-item>

      <el-form-item label="排序:" prop="sort">
        <el-input v-model.number="fromData.sort"></el-input> </el-form-item
      ><el-form-item label="备注:">
        <el-input
          v-model="fromData.desc"
          autocomplete="off"
        ></el-input> </el-form-item
      ><el-form-item label="颜色:" prop="color_code">
        <el-color-picker v-model="fromData.color_code"></el-color-picker>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditBrand",

  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增品牌",
      diagonStatus: "",
      labelPosition: "right",
      fromData: {
        id: "",
        name: "",
        code: "",
        color_code: "",
        sort: 1,
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        code: [{ required: true, message: "简码不能为空", trigger: "blur" }],
        sort: [
          {
            type: "number",
            required: true,
            message: "不能为空并且必须为数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增供应商";
        this.fromData = {
          id: "",
          name: "",
          code: "",
          color_code: "",
          sort: 1,
          desc: "",
        };
      } else {
        this.title = "编辑供应商";
        this.fromData = {
          id: this.item.id,
          name: this.item.name,
          code: this.item.code,
          color_code: this.item.color_code,
          sort: this.item.sort,
          desc: this.item.desc,
        };
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      const fromData = this.fromData;
      if (!fromData.color_code) {
        this.$message({
          message: "请选择颜色",
          type: "error",
        });
        return;
      }
      let data = {
        name: fromData.name,
        code: fromData.code,
        color_code: fromData.color_code,
        sort: fromData.sort,
        desc: fromData.desc,
      };
      if (this.type === "add") {
        this.handleAddComfirm(data);
        return;
      } else {
        data.id = fromData.id;
        this.handleEditComfirm(data);
      }
    },
    //新增提交
    async handleAddComfirm(data) {
      const res = await this.$store.dispatch("newSupplier", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(data) {
      const res = await this.$store.dispatch("editSupplier", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>